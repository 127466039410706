import React, {useState}from "react";
import {View, ImageBackground,} from "react-native";
import styles from "./style";


export default function Header() {

  const [larg, setLarg] = useState(null);

  setInterval(() => {
    var largura = window.screen.width;
    setLarg(largura);
  }, 100);

  return (
    <View style={styles.ContainerHeader}>
      <ImageBackground
        source={larg >= 601 ? "https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/OX%2Fbg.png?alt=media&token=d1dd4182-fbc9-4d9b-b4b3-2bbee5146334" : "https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/OX%2Fbg_mobile.png?alt=media&token=2f4bc7a5-9bcf-4915-b154-359623b2901c"} 
        style={styles.background}
      >
      </ImageBackground>
    </View>
  );
}
