import React, { useState, useEffect } from "react";
import { Text, View, TouchableOpacity, TextInput, Image, Linking, Alert} from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import UploadFile from "../../Functions/PickImage";
import UploadBd from "../../Functions/Upload";
import styles from "./style";
import { Picker } from '@react-native-picker/picker';

import  * as DocumentPicker from 'expo-document-picker';


export default function Body() {

  const [modalidade, setModalidade] = useState("");
  const [area, setArea] = useState("");
  const [titulo, setTitulo] = useState("");
  const [chave, setChave] = useState("");
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [co1, setCo1] = useState("");
  const [co2, setCo2] = useState("");
  const [co3, setCo3] = useState("");
  const [co4, setCo4] = useState("");
  const [co5, setCo5] = useState("");
  const [dataAtual, setDataAtual] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const [openAnimation, setOpenAnimation] = useState(false);
  const [botao, setBotao] = useState("Hacer upload del trabajo");
  const [bd, setBD] = useState(null);

  const [doc, setDoc] = useState("");

  const [arquivo, setArquivo] = useState("");

  const [loading, setLoading] = useState(false);

  const [coOpen, setCoOpen] = useState(false)
  const [co1Open, setCo1Open] = useState(false);
  const [co2Open, setCo2Open] = useState(false);
  const [co3Open, setCo3Open] = useState(false);
  const [co4Open, setCo4Open] = useState(false);

  const [larg, setLarg] = useState(null);

  const Enviando = "https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/OX%2FEnviando%20(1).gif?alt=media&token=96439257-6e11-49dd-8992-29df070984c2";
  const Enviado = "https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/OX%2FUpload%20feito%20(1).gif?alt=media&token=7b184fa3-3487-49d9-bbb3-82a7ce137f03";
  const Up = "https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/DAO%2FOX.gif?alt=media&token=a899dfc0-907c-47d6-8b54-adef1b7090d5";

  const Tick = () => {
    var data = new Date();

    var dia = String(data.getDate()).padStart(2, "0");
    var mes = String(data.getMonth() + 1).padStart(2, "0");
    var ano = data.getFullYear();
    setDataAtual(dia + "/" + mes + "/" + ano);
  };

  const Envio = async () => {
    if (
      modalidade !== "" &&
      area !== "" &&
      titulo !== "" &&
      chave !== "" &&
      nome !== "" &&
      email !== "" &&
      arquivo !== "" 
    ){
      const BackEnd = await UploadBd(modalidade,area, titulo, chave, arquivo, nome, email, co1, co2, co3, co4, co5, dataAtual);

      if(BackEnd ===  "Concluido"){
        setOpenModal(true)
        setOpenAnimation(false)
      }
    } else {
      alert("No fue posible enviar, ya que hay campos vacíos, rellene todos los campos para enviar de nuevo")
    }
  }

  setInterval(() => {
    var largura = window.screen.width;
    setLarg(largura);
  }, 100);

  const getUploader = async () => {
   if(loading === false && arquivo === "") {
    setLoading(true);
   } else {
    setLoading(false);
   }
  }


  const pickImage = async () => {

    try{
        let result = await DocumentPicker.getDocumentAsync({
            type: "*/*",
            copyToCacheDirectory: false,
            multiple: false,
        });
        
        console.log(result);

        if (!result.canceled) {

          setBotao("Espere...")

                 
        const mimeType = result.mimeType;
        const extensao = identificarExtensao(mimeType);

          var lastThree = result.name.substr(result.name.length - 3);

          setDoc(result.name)
          
          const File = await UploadFile(result.uri, extensao, mimeType);
          getUploader();
        
          setArquivo(File)
        } else {
          setLoading(false)
        }
  
    } catch(error) {

    }
  }


  function identificarExtensao(mimeType) {
    const mapeamento = {
        'text/plain': '.txt',
        'application/pdf': '.pdf',
        'application/msword': '.doc',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
        'application/vnd.ms-excel': '.xls',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
        'application/vnd.ms-powerpoint': '.ppt',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': '.pptx',
        'application/rtf': '.rtf',
        'application/vnd.oasis.opendocument.text': '.odt',
        'application/vnd.oasis.opendocument.spreadsheet': '.ods',
        'application/vnd.oasis.opendocument.presentation': '.odp',
        'application/x-latex': '.latex',
        'text/csv': '.csv',
        'text/html': '.html',
        'application/xhtml+xml': '.xhtml',
        'application/xml': '.xml',
        'application/json': '.json',
        // Adicione mais tipos MIME e extensões conforme necessário
    };
    
    return mapeamento[mimeType] || null;
}

useEffect( () => {
  if(arquivo !== "") {
    getUploader();
    Tick();
  }
},[arquivo])

  return (

      <View style={styles.ContainerBody}>
        {openAnimation === true &&
        
          <View style={styles.containerAnimation}>
          <Image
              style={styles.animation}
              source={{uri : Enviando}}
          />
         </View>
        }
        
        {openModal === true ? 
          <View style={styles.containerAnimation}>
            <Image
                style={styles.animationEnviado}
                source={require("../../../assets/check.svg")}
            />
            <Text style={styles.titleAnimationEnviado}>Su trabajo ha sido enviado!</Text>
            <Text style={styles.textAnimationEnviado}>✅ Le enviamos un correo electrónico de confirmación de envío. Recuerde verificar su bandeja de entrada y promociones.</Text>
            <Text style={styles.text2AnimationEnviado}>La comisión científica evaluará el trabajo enviado y recibirá un correo electrónico si el mismo ha sido aprobado o reprobado.</Text>
          </View>
          :
          <>
          {openAnimation === false &&
          <>
          <View style={larg >= 601 ? styles.box : styles.box_mobile}>
            {/*<Text style={larg >= 601 ? styles.title : styles.title_mobile }>Sumisión de trabajo</Text>*/}

              {/*<Text style={larg >= 601 ? styles.text : styles.text_mobile}>Período de la submisión:</Text>
            <Text style={larg >= 601 ? styles.text : styles.text_mobile}>28/02/2024 hasta 28/04/2024</Text>*/}

            <View style={{height: 40}}/>

            <Text style={larg >= 601 ? styles.text : styles.text_mobile_2}>Rellene la siguiente información con exactitud</Text>
            <Picker
                selectedValue={modalidade}
                style={larg >= 601 ? [styles.input, styles.shadowProp] : [styles.input_mobile, styles.shadowProp_mobile]}
                onValueChange={(itemValue) => setModalidade(itemValue)}>

                <Picker.Item label="Modalidad" value="Modalidad" />
                <Picker.Item label="Exposición" value="Exposición" />
                                            
            </Picker>

            <Picker
                selectedValue={area}
                style={larg >= 601 ? [styles.input, styles.shadowProp] : [styles.input_mobile, styles.shadowProp_mobile]}
                onValueChange={(itemValue) => setArea(itemValue)}>

                <Picker.Item label="Área temática" value="Área temática" />
                <Picker.Item label="Ortodoncia Correctiva" value="Ortodoncia Correctiva" />
                <Picker.Item label="Ortodoncia Preventiva y Ortopedia facial de los maxilares" value="Ortodoncia Preventiva y Ortopedia facial de los maxilares" />
                                            
            </Picker>

            <TextInput 
                style={larg >= 601 ? [styles.input, styles.shadowProp] : [styles.input_mobile, styles.shadowProp_mobile]}
                placeholder="Título del trabajo"
                placeholderTextColor="#000"
                type="text"
                onChangeText={(text) => setTitulo(text)}
                value={titulo} 
            />

            <TextInput 
                style={larg >= 601 ? [styles.input, styles.shadowProp] : [styles.input_mobile, styles.shadowProp_mobile]}
                placeholder="Palabra clave"
                placeholderTextColor="#000"
                type="text"
                onChangeText={(text) => setChave(text)}
                value={chave} 
            />
            {/*Upload*/}

            {loading === false && arquivo !== "" &&
              <Text style={larg >= 601 ? styles.text : styles.text_mobile_2}>Si desea cambiar el archivo, haga clic en "Archivo adjunto"</Text>
            }
            <View style={styles.divBottom}>
              {loading === false && arquivo === "" &&
                <TouchableOpacity style={[styles.bottom, styles.shadowProp2]} onPress={() => {pickImage()}}>
                  <Text style={styles.textBottom}>{botao}</Text>
                </TouchableOpacity>
              }

              {loading === true && arquivo === "" &&
                <Image
                  style={{width: 50, height: 50, marginTop: 20, alignItems: 'center'}}
                  source={{uri : Up}}
                />
              }

              {loading === false && arquivo !== "" &&
                <TouchableOpacity style={styles.upload} onPress={() => {setArquivo(""), setLoading(false), pickImage()}}>
                  <Text style={styles.textUpload}>Archivo adjunto</Text>
                </TouchableOpacity>
              }
              {/*Upload*/}

            </View>

            <Text style={larg >= 601 ? styles.text : styles.text_mobile}>Recuerde que el formato de archivo debe ser word.</Text>
              <TouchableOpacity onPress={() => {Linking.openURL("https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/OX%2FOX24_Normas%20para%20envi%CC%81o%20de%20trabajo.docx?alt=media&token=e1a2015f-440d-4d84-b25f-01731dc8861c")}}>
                <Text style={styles.textLink}>Haga clic aquí para ver as normas de envío</Text>
              </TouchableOpacity>
            

            <TextInput 
                style={larg >= 601 ? [styles.input, styles.shadowProp] : [styles.input_mobile, styles.shadowProp_mobile]}
                placeholder="Nombre del Autor(a)"
                placeholderTextColor="#000"
                type="name"
                onChangeText={(text) => setNome(text)}
                value={nome} 
            />

            <TextInput 
                style={larg >= 601 ? [styles.input, styles.shadowProp] : [styles.input_mobile, styles.shadowProp_mobile]}
                placeholder="Escriba su correo"
                placeholderTextColor="#000"
                type="email"
                onChangeText={(text) => setEmail(text)}
                value={email} 
            />

            {coOpen === false &&
              <View style={larg >= 601 ? styles.divButoomAdd : styles.divButoomAdd_mobile}>
                <TouchableOpacity style={larg >= 601 ? styles.bottomAdd: styles.bottomAdd_mobile } onPress={() => {setCoOpen(true)}}>
                  <Text style={styles.textBottomAdd}>Agregar coautor</Text>
                </TouchableOpacity>
              </View>  
            }

            {coOpen === true &&
              <>
              <TextInput 
                  style={larg >= 601 ? [styles.input, styles.shadowProp] : [styles.input_mobile, styles.shadowProp_mobile]}
                  placeholder="Nombre del coautor"
                  placeholderTextColor="#000"
                  type="name"
                  onChangeText={(text) => setCo1(text)}
                  value={co1} 
              />

              {co1Open === false &&
                <View style={larg >= 601 ? styles.divButoomAdd : styles.divButoomAdd_mobile}>
                  <TouchableOpacity style={larg >= 601 ? styles.bottomAdd: styles.bottomAdd_mobile} onPress={() => {setCo1Open(true)}}>
                    <Text style={styles.textBottomAdd}>Agregar coautor</Text>
                  </TouchableOpacity>
                </View>  
              }       
              </>
            }

            {co1Open === true &&
                <>
                  <TextInput 
                    style={larg >= 601 ? [styles.input, styles.shadowProp] : [styles.input_mobile, styles.shadowProp_mobile]}
                    placeholder="Nombre del coautor"
                    placeholderTextColor="#000"
                    type="name"
                    onChangeText={(text) => setCo2(text)}
                    value={co2} 
                  />

                  {co2Open === false &&
                    <View style={larg >= 601 ? styles.divButoomAdd : styles.divButoomAdd_mobile}>
                      <TouchableOpacity style={larg >= 601 ? styles.bottomAdd: styles.bottomAdd_mobile} onPress={() => {setCo2Open(true)}}>
                        <Text style={styles.textBottomAdd}>Agregar coautor</Text>
                      </TouchableOpacity>
                    </View>  
                  } 
                </>
            }

            {co2Open === true &&  
              <>
                <TextInput 
                    style={larg >= 601 ? [styles.input, styles.shadowProp] : [styles.input_mobile, styles.shadowProp_mobile]}
                    placeholder="Nombre del coautor"
                    placeholderTextColor="#000"
                    type="name"
                    onChangeText={(text) => setCo3(text)}
                    value={co3} 
                /> 

                {co3Open === false &&
                    <View style={larg >= 601 ? styles.divButoomAdd : styles.divButoomAdd_mobile}>
                      <TouchableOpacity style={larg >= 601 ? styles.bottomAdd: styles.bottomAdd_mobile} onPress={() => {setCo3Open(true)}}>
                        <Text style={styles.textBottomAdd}>Agregar coautor</Text>
                      </TouchableOpacity>
                    </View>  
                  } 
              </>
            }         
    
            {co3Open === true &&
              <>
                <TextInput 
                    style={larg >= 601 ? [styles.input, styles.shadowProp] : [styles.input_mobile, styles.shadowProp_mobile]}
                    placeholder="Nombre del coautor"
                    placeholderTextColor="#000"
                    type="name"
                    onChangeText={(text) => setCo4(text)}
                    value={co4} 
                />

                {co4Open === false &&
                    <View style={larg >= 601 ? styles.divButoomAdd : styles.divButoomAdd_mobile}>
                      <TouchableOpacity style={larg >= 601 ? styles.bottomAdd: styles.bottomAdd_mobile} onPress={() => {setCo4Open(true)}}>
                        <Text style={styles.textBottomAdd}>Agregar coautor</Text>
                      </TouchableOpacity>
                    </View>  
                } 
              </>
            }          

            {co4Open === true &&
              <TextInput 
                    style={larg >= 601 ? [styles.input, styles.shadowProp] : [styles.input_mobile, styles.shadowProp_mobile]}
                    placeholder="Nombre del coautor"
                    placeholderTextColor="#000"
                    type="name"
                    onChangeText={(text) => setCo5(text)}
                    value={co5} 
              />
            }

          
            <TouchableOpacity style={[styles.bottomEnviar, styles.shadowProp2]} onPress={() => {Envio()}}>
                <Text style={styles.textBottom}>Enviar trabajo</Text>
            </TouchableOpacity>

          </View>
        
        </>
        }
        </>
      }
    </View>
    
     
  );
}
